import React, { Fragment } from "react"
import { compose } from "recompose"

import Layout from "../components/layout"
import { AuthUserContext, withAuthorization } from "../components/Session"

import PasswordChangeForm from "../components/PasswordChange"

const AccountPageBase = () => (
  <Fragment>
    <AuthUserContext.Consumer>
      {authUser => <PasswordChangeForm />}
    </AuthUserContext.Consumer>
  </Fragment>
)

const condition = authUser => !!authUser

const AccountPage = compose(withAuthorization(condition))(AccountPageBase)

export default () => (
  <Layout>
    <div className="row centered">
      <div className="col col-6">
        <h1 className="heading--l">Reset Password</h1>
        <AccountPage />
      </div>
    </div>
  </Layout>  
)


